<template>
  <div class="grid crud-demo">
    <div class="col-12">
      <div class="card">
        <Toast/>
        <Toolbar class="mb-4">
          <template v-slot:end>
            <div class="my-2">
              <Button label="Novo" icon="pi pi-plus" class="p-button-success mr-2" @click="$router.push({name: 'contato'})" />
            </div>
          </template>
        </Toolbar>

        <DataTable ref="dt" :value="contacts" dataKey="id"
                   :paginator="true"
                   :filters="filters"
                   :lazy="true"
                   :total-records="totalRecords"
                   :loading="loading"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   :rowsPerPageOptions="[5,10,25]"
                   :rows="10"
                   currentPageReportTemplate="Total {totalRecords} produtos"
                   responsiveLayout="scroll"
                   @page="findAll($event)"
        >
          <template #empty>
            Nenhum registro encontrado.
          </template>
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Gerenciamento de Contatos</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                                <i class="pi pi-search" />
                                <InputText @change="findAll(page)" v-model="pesquisar" placeholder="Pesquisar..." />
                            </span>
            </div>
          </template>
          <Column field="name" header="Nome" :sortable="true">
            <template #body="slotProps">
              <Skeleton v-show="skeletor"></Skeleton>
              <span class="p-column-title">Nome</span>
              <span v-show="!skeletor">{{slotProps.data.name}}</span>
            </template>
          </Column>
          <Column field="provider" header="Celular">
            <template #body="slotProps">
              <Skeleton v-show="skeletor"></Skeleton>
              <span class="p-column-title">Celular</span>
              <span v-show="!skeletor">{{slotProps.data.phone}}</span>
            </template>
          </Column>
          <Column field="provider" header="E-mail">
            <template #body="slotProps">
              <Skeleton v-show="skeletor"></Skeleton>
              <span class="p-column-title">E-mail</span>
              <span v-show="!skeletor">{{slotProps.data.email}}</span>
            </template>
          </Column>
          <Column field="provider" header="Aniversário">
            <template #body="slotProps">
              <Skeleton v-show="skeletor"></Skeleton>
              <span class="p-column-title">Aniversário</span>
              <span v-show="!skeletor">{{slotProps.data.birth}}</span>
            </template>
          </Column>
          <Column field="categoryType" header="Categoria" :sortable="true">
            <template #body="slotProps">
              <Skeleton v-show="skeletor"></Skeleton>
              <span class="p-column-title">Categoria</span>
              <span v-show="!skeletor" class="bg-orange-200 mr-2 mt-2 text-black-400 border-round py-1 px-2 text-sm">
                {{slotProps.data.categoryType}}
              </span>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button v-show="!skeletor" icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="edit(slotProps.data)" />
              <Button v-show="!skeletor" icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDelete(slotProps.data)" />
            </template>
          </Column>
        </DataTable>

        <Dialog v-model:visible="deleteContactDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="contact">Tem certeza de que deseja excluir <b>{{ contact.name }}</b>?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteContactDialog = false"/>
            <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deletar(contact.id)" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import ContactService from '@/service/ContactService';

export default {
  data() {
    return {
      contacts: null,
      deleteContactDialog: false,
      filters: {},
      contactService: null,
      selectedCity: null,
      contact: null,
      skeletor: false,
      totalRecords: 0,
      pageSize: 0,
      loading: false,
      pesquisar: null,
      page: {
        originalEvent: {
          rows: 10,
          page: 0
        }
      }
    }
  },
  created() {
    this.contactService = new ContactService();
    this.initFilters();
  },
  async mounted() {
    this.loading = true;
    await this.findAll(this.page);
    this.loading = false;
  },

  methods: {

    async findAll(event) {
      let response = null;
      this.skeletor = true;

      if(this.pesquisar) {
          response = await this.contactService.findAll(event.originalEvent.rows, event.originalEvent.page, this.pesquisar);
      } else {
        response = await this.contactService.findAll(event.originalEvent.rows, event.originalEvent.page);
      }
      if (response.status === 200 || response.status === 204) {
        this.totalRecords = response.data.totalElements;
        this.contacts = response.data.content;
      }
      this.skeletor = false;
    },
    edit(contact) {
      this.$router.push({name: 'contato', query: {id: contact.id}});
    },
    confirmDelete(product) {
      this.contact = product;
      this.deleteContactDialog = true;
    },
    async deletar(id) {
      this.loading = true;
      try {
        const response = await this.contactService.delete(id);
        if (response.status === 200) {
          this.deleteContactDialog = false;
          this.contact = {};
          this.$toast.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Contato removido com sucesso,',
            life: 3000
          });
          await this.findAll(this.page);
        }
      } catch(e) {
        this.deleteContactDialog = false;
        this.$toast.add({
          severity: 'warn',
          summary: 'Alerta',
          detail: 'Contato não pode ser removido, pois possuí vinculo com outros dados,',
          life: 4000
        });
      }
      this.loading = false;
    },

    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.contacts.length; i++) {
        if (this.contacts[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },

    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    }
  }
}
</script>

<style scoped lang="scss">
.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-true {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-false {
    background: #FFCDD2;
    color: #C63737;
  }
}
</style>
